/* @flow strict */

import GitHubIcon from 'assets/icons/github.png';
import LinkedInIcon from 'assets/icons/linkedin.png';
import CourseraLogo from 'assets/images/coursera.png';
import GoogleLogo from 'assets/images/google.png';
import IBMLogo from 'assets/images/ibm.png';
import MetaLogo from 'assets/images/meta.png';
import MicrosoftLogo from 'assets/images/microsoft.png';
import type { Props as CardProps } from 'components/Card';
import { getImagePath, type LinkIcon } from 'utils/common';

const FOLDER_PATH = 'about';
export const ICON_WIDTH = 32;
export const ICON_HEIGHT = 32;
export const IMAGE_WIDTH = 64;
export const IMAGE_HEIGHT = 64;
const getAboutIconPath = (
  file: string,
  width?: number,
  height?: number
): string => {
  return getImagePath(
    FOLDER_PATH,
    file,
    width ?? ICON_WIDTH,
    height ?? ICON_HEIGHT
  );
};
const getAboutImagePath = (file: string): string => {
  return getImagePath(FOLDER_PATH, file, IMAGE_WIDTH, IMAGE_HEIGHT);
};

export const socials: Array<LinkIcon> = [
  {
    name: 'GitHub',
    to: {
      pathname: 'https://github.com/denzelchung',
    },
    src: GitHubIcon,
    url: getAboutIconPath('github.png'),
    alt: 'Link to GitHub',
  },

  {
    name: 'LinkedIn',
    to: {
      pathname: 'https://www.linkedin.com/in/denzelchung/',
    },
    src: LinkedInIcon,
    url: getAboutIconPath('linkedin.png', 38),
    alt: 'Link to LinkedIn',
    width: 38,
  },
];

export const experiences: Array<CardProps> = [
  {
    img: GoogleLogo,
    url: getAboutImagePath('google.png'),
    alt: 'Google',
    title: 'Software Engineer',
    subtitle: 'Google',
    date: 'Nov 2022 - Present',
  },
  {
    img: MetaLogo,
    url: getAboutImagePath('meta.png'),
    alt: 'Meta',
    title: 'Software Engineer',
    subtitle: 'Meta (via TEKsystems)',
    date: 'Jun 2021 - Nov 2022',
  },
  {
    img: IBMLogo,
    url: getAboutImagePath('ibm.png'),
    alt: 'IBM',
    title: 'Associate Full Stack Developer',
    subtitle: 'IBM',
    date: 'Oct 2020 - Jun 2021',
  },
];

export const skills = [
  'JavaScript',
  'HTML / CSS',
  'SQL',
  'Python',
  'Java',
  'Google Cloud Platform (GCP)',
  'Amazon Web Services (AWS)',
];

export const certifications: Array<CardProps> = [
  {
    img: MicrosoftLogo,
    url: getAboutImagePath('microsoft.png'),
    alt: 'Microsoft',
    title: 'Microsoft Certified: Azure Fundamentals',
    subtitle: 'Microsoft',
    date: 'Issued Feb 2021',
    credentialId: 'H670-9866',
    href: 'https://www.credly.com/badges/a2e20d82-d496-4d67-b875-7ec682c5ac6d/public_url',
  },
  {
    img: CourseraLogo,
    url: getAboutImagePath('coursera.png'),
    alt: 'Coursera',
    title: 'Architecting with Google Compute Engine Specialization',
    subtitle: 'Coursera - Google Cloud',
    date: 'Issued Mar 2020',
    credentialId: 'AFSCVNJAUE7Y',
    href: 'https://www.coursera.org/account/accomplishments/specialization/AFSCVNJAUE7Y',
  },
  {
    img: CourseraLogo,
    url: getAboutImagePath('coursera.png'),
    alt: 'Coursera',
    title: 'Full Stack Web Development Specialization',
    subtitle: 'Coursera - The Hong Kong University of Science and Technology',
    date: 'Issued Jun 2016',
    credentialId: 'YNQ92PE4VWGR',
    href: 'https://www.coursera.org/account/accomplishments/specialization/YNQ92PE4VWGR',
  },
];
